<template>
  <div class="background-container">
    <div class="text-content">
      <Loading :loading="loading"></Loading>
      <div v-if="this.$route.query.sso && !notYet && !razPlusTeacher" style="text-align: center;">
        <p style="font-size: 20px; font-weight: bold;">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</p>
        <p>&nbsp;</p>
        <p style="font-size: 20px; font-weight: bold;">Please wait, you are being redirected to the relevant platform.</p>
      </div>
      <div v-if="notYet">
        <p style="font-weight: bold;">Değerli Öğrencimiz,</p>
        <p>Raz-Plus'a hoş geldiniz. Giriş talebiniz işleme alınmıştır ve hesabınız, en geç 24 saat içerisinde aktif hale gelecektir. Bu süre zarfında beklediğiniz için teşekkür ederiz.</p>
        <p>Size destek olabileceğimiz her türlü konuda, 0850 550 50 90 numaralı telefonumuzdan ya da diğer destek kanallarımızdan bize ulaşabilirsiniz.</p>
        <p>&nbsp;</p>
        <p style="font-weight: bold;">Dear Student,</p>
        <p>Welcome to Raz-Plus. Your login request has been processed, and your account will be activated within 24 hours. We appreciate your patience during this time.</p>
        <p>If you need any assistance, please feel free to contact us at 0850 550 50 90 or through our other support channels</p>
      </div>
      <div v-if="razPlusTeacher" style="width: 600px;">
        <p style="font-weight: bold;">Dear {{ this.uesLmsUserData.user_name }},</p>
        <p>&nbsp;</p>
        <p><strong>Please select the class you wish to enter:</strong><br />(Please make sure your browser does not block pop-ups.)</p>
        <p>&nbsp;</p>
        <div v-for="(class_name, class_id, key) in uesLmsUserData.class_name" :key="key" style="display:inline-flex;">
          <div style="background-color: #118019; margin: 6px; display: inline-block; font-weight: bold;">
            <div @click="getRazToken(class_id, 'teacher')" style="padding: 10px 20px; color: #fff; cursor: pointer;">
              {{ class_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import _ from 'lodash';
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "RazPlus",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'DZYQSbQQKmruR4ZYOLD%s7163BZjoyViqF7gWy.A6Gd9JAyXCL!eNGsrEJVcPUe',
      // ueslms_service_baseurl: 'http://localhost:4000', // local
      ueslms_service_baseurl: 'https://ueslms-service-api.ey.r.appspot.com', // prod
      userData: null,
      user_id: null,
      user_role: null,
      token: null,
      app_code: 'ues_learningaz_sso',
      notYet: false,
      razPlusTeacher: false,
      uesLmsUserData: [],
      teacherClassCount: 0,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      this.loading = true
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.ueslmsCheckUser();
    } else {
      location.href = "https://service.ueslms.com";
    }
  },
  methods: {
    ueslmsCheckUser() {
      // axios.post('https://ueslms.test/api/v2/raz-plus/user/check', {user: this.userData})
      axios.post('https://www.ueslms.com/api/v2/raz-plus/user/check', {user: this.userData})
          .then(response => {
            // console.log('CHECK RESPONSE', response.data)
            if (response.data.success) {
              // console.log(response.data)
              this.uesLmsUserData = response.data.user
              if (response.data.user.minutes > 1080) {
                this.user_id = response.data.user.user_id; // + '-' + response.data.user.class_id2;
                this.user_role = response.data.user.role;
                if (this.user_role === 'student') {
                  this.getRazToken(this.user_id, this.user_role)
                } else {
                  this.checkTeacherClassCount()
                }
              } else {
                this.loading = false;
                this.notYet = true;
              }
            } else {
              // alert('create')
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      // axios.post('https://ueslms.test/api/v2/raz-plus/user/create', this.userData)
      axios.post('https://www.ueslms.com/api/v2/raz-plus/user/create', this.userData)
          .then(() => { // response
            // Kayıt yeni oluşmuşsa beklemesi gerekiyor.
            this.loading = false;
            this.notYet = true;
            // this.user_id = response.data.user.user_id;
            // this.user_role = response.data.user.type;
            // this.getRazToken()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    checkTeacherClassCount() {
      let classCount = _.size(this.uesLmsUserData.class_name)
      // console.log('SINIF SAYISI', classCount)
      this.teacherClassCount = classCount;
      if (classCount === 1) {
        // tek sınıf varsa direkt yönlendirme yapılabilir
        let classId = Object.keys(this.uesLmsUserData.class_name)[0]
        this.getRazToken(classId, 'teacher')
      } else {
        this.loading = false
        this.razPlusTeacher = true;
      }
    },

    async getRazToken(id, role) {
      this.loading = true;
      let url = `${this.ueslms_service_baseurl}/sso/raz-plus/token`;
      let data = {
        user_id: '',
        user_role: '',
      }
      if (role === 'student') {
        data.user_id = id;
        data.user_role = 'student';
      } else {
        const keys = Object.keys(this.uesLmsUserData.virtuals);
        const values = Object.values(this.uesLmsUserData.virtuals);
        const index = keys.indexOf(id);
        data.user_id = values[index];
        data.user_role = 'teacher';
      }
      // console.log('ID', id)
      // console.log('DATA', data)
      await axios.post(url, data)
          .then(response => {
            this.token = response.data.token;
            // console.log('TOKEN', this.token);
            if (role === 'student') {
              this.goRazPlus()
            } else {
              this.goRazPlusTeacher()
            }
          })
          .catch(response => {
            console.log('ERR', response);
            this.loading = false;
          })
    },

    goRazPlus() {
      this.loading = false;
      const url = "https://www.kidsa-z.com/main/LoginExternal/?app=" + this.app_code + "&token=" + this.token;
      location.href = url;
    },

    goRazPlusTeacher() {
      const url = "https://www.kidsa-z.com/main/LoginExternal/?app=" + this.app_code + "&token=" + this.token;
      // öğretmen sınıf sayısı 1 ise direkt yönlendirme yapıyoruz
      if (this.teacherClassCount === 1) {
        location.href = url;
      } else {
        this.loading = false;
        // birden fazla sınıf varsa yeni sekmede açıyoruz
        window.open(url, '_blank');
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.background-container {
  margin: 0 auto;
  position: relative;
  width: 1200px;
  min-height: 675px;
  background-image: url('/assets/images/bg-raz-plus.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: start;
}

.text-content {
  width: 700px;
  margin-top: 175px;
  text-align: left;
  color: white;
}

p {
  font-size: 16px;
  line-height: 26px;
}
</style>
