<template>
  <div class="flex-center position-ref">
    <Loading :loading="loading"></Loading>
    <div class="content">
      <img src="/assets/logos/espresso.png" alt="" />
      <h3 v-if="!userInfo">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
      <div  v-else>
        <strong>Platforma giriş yapabilmek için kullanıcı bilgileriniz aşağıdaki gibidir:</strong><br />
        <br />
        Kullanıcı Adı: <br />
        Parola: <strong>12345</strong><br />
        <br />
        <a href="https://app.discoveryeducation.com/learn/signin?UR=S" target="_blank">Platforma Git &raquo;</a>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import CryptoJS from "crypto-js";
import axios from "axios";

export default {
  name: "Espresso",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'EDcseSrkMx%Nvug6VptLyR85Pq.KZ4J-Yd7QbA9H2F!XBGCa3jWzTf',
      userData: null,
      userInfo: false,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      this.loading = true;
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.userData.branch = this.userData.class[0]['branch'];
      this.userData.class = this.userData.class[0]['grade'];
      this.ueslmsCheckUser()
    }
  },
  methods: {
    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.userData.user_id = response.data.user.user_id;
              this.userData.campus_id = response.data.user.campus_id;
              this.espressoInfo()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            this.userData.user_id = response.data.user.user_id;
            this.userData.campus_id = response.data.user.campus_id;
            this.espressoInfo()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    espressoInfo() {
      this.loading = false;
      this.userInfo = true
    }
  }
}
</script>
