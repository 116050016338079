<template>
  <div class="flex-center position-ref">
    <div class="content">
      <Loading :loading="loading"></Loading>

      <div v-if="this.bookCodeDiv || this.onlineCodeDiv" style="margin: 0 0 20px 0">
        <img src="/assets/images/richmond-logo.png" style="height: 50px;">
      </div>

      <div v-else>
        <img src="/assets/logos/richmondlp.png">
        <h3 v-if="this.$route.query.sso">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
      </div>

      <div v-if="this.onlineCodeDiv" style="background-color: #efefef; padding: 10px; text-align: left; width: 750px; line-height: 24px;">
        <h3>Richmond Learning Platform Aktivasyon Adımları</h3>
        <div>
          <strong style="color:#f00;">1.</strong> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <strong>"Kodu Kopyala"</strong> butonuna basarak kitap kodunuzu kopyalayınız.<br />
          <strong style="color:#f00;">2.</strong> Altta gördüğünüz <strong>"Richmond Learning Platform"</strong> simgesine tıklayınız ve siteye geçiş yapınız.<br />
          <strong style="color:#f00;">3.</strong> Ekranda <strong>"MY PRODUCTS"</strong> başlığı altında bulunan <strong>"ADD ACCESS CODE"</strong> butonuna basın ve kopyaladığınız kodu <strong>"Please Enter a Code"</strong> alanına yazınız.<br />
          <strong style="color:#f00;">4.</strong> Kodu yapıştırdıktan sonra sağ alt bölümde görünen <strong>"Add Product"</strong> butonuna tıklayınız.<br />
          <strong style="color:#f00;">5.</strong> Ardından ekranda göreceğiniz <strong>"START"</strong> butonuna tıklayınız.<br />
          <strong style="color:#f00;">6.</strong> Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.<br />
          <strong style="color:#f00;">7.</strong> Sonraki erişimlerinizde sadece <strong>"Richmond Learning Platform"</strong> butonuna basıp platformu kullanabilirsiniz.<br />
        </div>
        <div style="margin: 20px auto; width:400px; padding: 10px; background-color: #bcf1b6; align-items: center; text-align: center;">
          <strong style="margin-right:10px;">{{ this.userData.class_rlp_code }}</strong>
          <button type="button" class="code-copy-button" @click.prevent="copyCode">Kodu Kopyala</button>
        </div>
        <table style="width: 100%;">
          <tr>
            <td style="width: 50%; text-align: center;">
              <a href="#/go_rlp" @click.prevent="goRLPSso()">
                <img src="/assets/logos/richmondlp.png">
              </a>
            </td>
            <td style="width: 50%; text-align: center;">
              <a href="https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y" target="_blank">
                <img src="/assets/images/youtube.jpg" style="width:300px;">
              </a>
            </td>
          </tr>
        </table>
      </div>

      <br /><br />

      <div v-if="this.bookCodeDiv" style="background-color: #efefef; padding: 10px; text-align: left; width: 750px; line-height: 24px;">
        <h3>Richmond Learning Platform Aktivasyon Adımları</h3>
        <div>
          <strong style="color:#f00;">1.</strong> Platforma ilk defa erişiyorsanız altta gördüğünüz <strong>"Richmond Learning Platform"</strong> simgesine tıklayınız ve siteye geçiş yapınız.<br />
          <strong style="color:#f00;">2.</strong> Ekranda <strong>"MY PRODUCTS"</strong> başlığı altında bulunan <strong>"ADD ACCESS CODE"</strong> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <strong>"Please Enter a Code"</strong> alanına yazınız.<br />
          <strong style="color:#f00;">3.</strong> Kodu yazdıktan sonra sağ alt bölümde görünen <strong>"Add Product"</strong> butonuna tıklayınız.<br />
          <strong style="color:#f00;">4.</strong> Ardından ekranda göreceğiniz <strong>"START"</strong> butonuna tıklayınız.<br />
          <strong style="color:#f00;">5.</strong> Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.<br />
          <strong style="color:#f00;">6.</strong> Sonraki erişimlerinizde sadece <strong>"Richmond Learning Platform"</strong> butonuna basıp platformu kullanabilirsiniz.<br />
        </div>
        <table style="width: 100%;">
          <tr>
            <td style="width: 50%; text-align: center;">
              <a href="#/go_rlp" @click.prevent="goRLPSso()">
                <img src="/assets/logos/richmondlp.png">
              </a>
            </td>
            <td style="width: 50%; text-align: center;">
              <a href="https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe" target="_blank">
                <img src="/assets/images/youtube.jpg" style="width:300px;">
              </a>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import CryptoJS from 'crypto-js';
import axios from "axios";

export default {
  name: "RLP",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N',
      secretKey2: 'yPPLPWiyjAP7KwWRJMakBTWFTcAXwefUBCqRXw9tyaENYxiPTmiQDtTVrFap9Lz4', // RLP servisine giderken
      userData: null,
      onlineCodeDiv: false,
      bookCodeDiv: false,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.ueslmsCheckUser()
    }
  },
  methods: {
    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/rlp/user/check', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user;
              this.checkRLPCode()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/rlp/user/create', this.userData)
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user;
              this.checkRLPCode()
            } else {
              console.log('ERR', response)
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    checkRLPCode() {
      // 2024-2025 sezonu için bazı okulların özel lms'de kod gösterme devreye aldığımız için
      // ilgili okullarda bu işlemi devre dışı bırakıyoruz.
      if (
          this.userData.school_id === 63    ||  // Final Okulları
          this.userData.school_id === 85177 ||  // İskenderun Tek Koleji
          this.userData.school_id === 85    ||  // Gundogdu Schools
          this.userData.school_id === 274   ||  // Adana Erkan Koleji
          this.userData.school_id === 11843 ||  // Diyarbakır Ortadoğu Koleji
          // this.userData.school_id === 97315 ||  // Ekin Koleji
          // this.userData.school_id === 97328 ||  // Malatya İntegral Okulları
          // this.userData.school_id === 97326 ||  // Hatay Doğuş Okulları
          this.userData.school_id === 11614     // Bilfen Okulları
      )
      {
        this.goRLPSso()
      } else {
        // kampüs'te rlp var ve kitabındaki kodu kullanacak
        if (this.userData.rlp_institute_code && this.userData.class_rlp_code === '###') {
          this.bookCodeDiv = true
        }
        // kampüste rlp var ve online'da yazan kodu kullanacak
        else if (this.userData.rlp_institute_code && this.userData.class_rlp_code !== null) {
          this.onlineCodeDiv = true
        }
        // kampüste rlp var ve direkt gidebilir
        else if (this.userData.rlp_institute_code) {
          this.goRLPSso()
        }
        // rlp_institute_code tanımlı değil. yani kampüste rlp tanımlı değil.
        else {
          alert('kampuste rlp_institute_code tanimli degil')
        }
      }
    },

    goRLPSso() {
      const textJson = JSON.stringify(this.userData);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, this.secretKey2).toString();
      // console.log('ciphertext', ciphertext);
      window.location.href = "https://richmondlp.com/ueshub/sessions/new?token=" + btoa(ciphertext);
    },

    copyCode() {
      navigator.clipboard.writeText(this.userData.class_rlp_code);
      alert('Kod kopyalandı.')
    }

  }
}
</script>

<style scoped>
.code-copy-button {
  background-color: #037148;
  border: none;
  color: white;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.code-copy-button:hover {
  background-color: #034e32;
}
</style>
