<template>
  <div>
    <Loading :loading="loading"></Loading>
    <h2>Okyanus Web Service</h2>
  </div>
</template>

<script>
/*
Hexagonia
RazPlus
SensationsEnglish
Deutschbesten
https://service.ueslms.com/#/okyanus/?ogrenciId=xxx&uygulama=Hexagonia
https://localhost:8080/#/okyanus/?ogrenciId=xxx&uygulama=Hexagonia

5. Sınıf
0x02000000CD7ADC80ED34849F6269BC829B35E1D9AE123DAF469382826B49F858746A1F3F

6. Sınıf
0x020000006CF622351E0E8B7A0DA113A96B7EB610841CA62916BC3D1E980F3A853E64FDD4

9. Sınıf
0x020000001BA4B8701796C2585E7075C010E47D2FF5F1D18B470EF8078A0B1CB464D7F1DF

10. Sınıf
0x0200000044755E72AA6FC9BD058BD7B8C0C4542CF73A841BB4040D752DAC960FD4CD1EE7

11. Sınıf
0x020000006FD7ACC7CFAC40C95BCAC68036020FCBBDC726EA74A60A01C3BFC96B9B3EBCE3
 */
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "Okyanus",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      apiKey: 'BC63F515-92E5-42BC-BBD7-AD00C64E8D3E',
      userKey: null,
      userRole: null,
      userId: 0,
      secretKey: "HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8",
      hexagoniaSecretKey: "HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8",
      uesPortalsSecretKey: "HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8",
      razPlusSecretKey: "DZYQSbQQKmruR4ZYOLD%s7163BZjoyViqF7gWy.A6Gd9JAyXCL!eNGsrEJVcPUe",
      sensationsEnglishSecretKey: "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy",
      userData: {},
      platform: null,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.uygulama) {
      this.platform = query.uygulama;
      this.getUserKey();
      this.getUserDetailsForUesLms();
    } else {
      console.log('HATA: uygulama parametresi bulunamadi.')
    }
  },
  methods: {
    getUserKey() {
      if (this.$route.query.ogrenciId) {
        this.userKey = this.$route.query.ogrenciId
        this.userRole = 'student'
      } else if (this.$route.query.ogretmenId) {
        this.userKey = this.$route.query.ogretmenId
        this.userRole = 'teacher'
      }
    },
    // OKYANUSTAN OGRENILEN ID UESLMS SERVISINE GONDERILEREK KULLANICI BILGILERI OGRENILIYOR
    async getUserDetailsForUesLms() {
      this.loading = true
      await axios.post('https://www.ueslms.com/api/v2/okyanus/user/check', { key: this.userKey, apikey: this.apiKey })
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user
              if (response.data.user.class === 5 && this.platform === 'Hexagonia') {
                this.goHexagonia()
              }
              else if (response.data.user.class === 6 && this.platform === 'RazPlus') {
                this.goRazPlus()
              }
              else if ((response.data.user.class === 9 || response.data.user.class === 10 || response.data.user.class === 11) && this.platform === "SensationsEnglish") {
                this.goSensationsEnglish()
              }
              else if ((response.data.user.class === 9 || response.data.user.class === 10 || response.data.user.class === 11) && this.platform === "Deutschbesten") {
                this.goDeutschbesten()
              }
            } else {
              console.log('ERR: UESLMS servisinden bilgi alinamadi.')
            }
            this.loading = false
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },
    goHexagonia() {
      // console.log(this.userData)
      const myObject = {};
      myObject.school_id = 3432; // okyanus
      myObject.campus_name = this.userData.campus_name;
      myObject.first_name = this.userData.first_name;
      myObject.last_name = this.userData.last_name;
      myObject.username = this.userData.login_name;
      myObject.password = (this.userRole === 'student') ? "123456" : 'Ues1234!';
      if (this.userRole === 'student') {
        myObject.class = this.userData.class;
        myObject.branch = this.userData.branch;
      } else {
        myObject.classes = this.userData.classes;
      }
      // console.log('myObject', myObject)
      const textJson = JSON.stringify(myObject);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, this.hexagoniaSecretKey).toString();
      if (this.userRole === 'student') {
        location.href = "https://www.hexagonia.com.tr/?sso=" + btoa(ciphertext);
      } else {
        location.href = "https://www.uesportals.com/#/login/signin?sso=" + btoa(ciphertext);
      }
    },

    goRazPlus() {
      // console.log('userData', this.userData);
      const myObject = {};
      myObject.school_id = 3432; // okyanus
      myObject.campus_id = 0;
      myObject.campus_name = this.userData.campus_name;
      myObject.first_name = this.userData.first_name;
      myObject.last_name = this.userData.last_name;
      myObject.username = this.userData.login_name;
      myObject.role_type = this.userRole;
      myObject.class = this.userData.class;
      myObject.branch = this.userData.branch;
      // console.log('myObject', myObject)
      const textJson = JSON.stringify(myObject);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, this.razPlusSecretKey).toString();
      if (this.userRole === 'student') {
        location.href = "https://service.ueslms.com/#/raz-plus/?sso=" + btoa(ciphertext);
      } else {
        location.href = "https://service.ueslms.com/#/raz-plus-login";
      }
    },

    goSensationsEnglish() {
      // console.log('userData', this.userData);
      const myObject = {};
      myObject.school_id = 3432; // okyanus
      myObject.school_name = 3432; // okyanus
      myObject.campus_id = 0;
      myObject.campus_name = this.userData.campus_name;
      myObject.first_name = this.userData.first_name;
      myObject.last_name = this.userData.last_name;
      myObject.username = this.userData.login_name;
      myObject.role_type = this.userRole;
      myObject.class = [
        {
          class: this.userData.class,
          branch: this.userData.branch,
        }
      ];
      // console.log('myObject', myObject)
      const textJson = JSON.stringify(myObject);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, this.sensationsEnglishSecretKey).toString();
      location.href = "https://service.ueslms.com/#/sensations/?sso=" + btoa(ciphertext);
    },

    goDeutschbesten() {
      // console.log('userData', this.userData)
      location.href = "https://deutschbesten.com/#/?username=" + btoa(this.userData.login_name) + "&password=" + btoa((this.userRole === 'student') ? '123456' : 'Ues1234!');
    },

  }
}
</script>
