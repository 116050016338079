<template>
  <div class="background-container">
    <div class="text-content">
      <Loading :loading="loading"></Loading>

      <div class="container">
        <h2 style="font-size: 26px; text-align: center;">Teacher Login</h2>

        <div style="margin-top: 20px;">
          <label for="username"><b>Username</b></label>
          <input type="text" placeholder="Enter Username" v-model="username" id="username">
        </div>

        <div style="margin-top: 20px;">
          <label for="password"><b>Password</b></label>
          <input type="password" placeholder="Enter Password" v-model="password" id="password">
        </div>

        <button @click.prevent="clickLogin" type="submit">Login</button>
        <div class="error" v-if="formMessage">{{ message }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "RazPlusLogin",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      username: null, // '5A_raz_100081', //
      password: null, // 'Ues1234!1', //
      secretKey: "DZYQSbQQKmruR4ZYOLD%s7163BZjoyViqF7gWy.A6Gd9JAyXCL!eNGsrEJVcPUe",
      formMessage: false,
      message: null,
    }
  },
  methods: {
    clickLogin() {
      this.loading = true;
      this.ueslmsCheckUser();
    },

    async ueslmsCheckUser() {
      await axios.post('https://www.ueslms.com/api/v2/raz-plus/teacher/login', { username: this.username, password: this.password })
          .then(response => {
            if (response.data.success) {
              var textJson = JSON.stringify(response.data.user);
              var uriText = decodeURIComponent(textJson)
              var ciphertext = CryptoJS.AES.encrypt(uriText, this.secretKey).toString();
              window.location.href = "https://service.ueslms.com/#/raz-plus?sso=" + btoa(ciphertext);
              // window.location.href = "https://localhost:8080/#/raz-plus?sso=" + btoa(ciphertext);
            } else {
              this.formMessage = true;
              this.message = response.data.message;
              this.loading = false;
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.background-container {
  margin: 0 auto;
  position: relative;
  width: 1200px;
  min-height: 675px;
  background-image: url('/assets/images/bg-raz-plus.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: start;
}

.text-content {
  width: 700px;
  margin-top: 150px;
  text-align: left;
  color: white;
}

p {
  font-size: 16px;
  line-height: 26px;
}

/* Bordered form */
form {
  border: 3px solid #f1f1f1;
}

/* Full-width inputs */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Set a style for all buttons */
button {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Add a hover effect for buttons */
button:hover {
  opacity: 0.8;
}

/* Extra style for the cancel button (red) */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the avatar image inside this container */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
  width: 40%;
  border-radius: 50%;
}

/* Add padding to containers */
.container {
  margin: 0 auto;
  width: 400px;
  padding: 16px;
}

/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}

.error {
  margin-top: 15px;
  color: #c00;
  font-weight: bold;
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}
</style>
