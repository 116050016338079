<template>
  <div>
    <Loading :loading="loading"></Loading>
    <h2>UES RLP Login</h2>
    <input type="text" v-model="username" placeholder="kullanıcı adı"/><br/>
    <input type="password" v-model="password" placeholder="parola"/><br/>
    <br/>
    <button @click.prevent="goRLPService()" type="button">Gönder</button>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "UESRLPLogin",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      username: null, // 'b10d3cb6776',
      password: null, // '',
      secretKey: "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N",
      // secretKey2: 'yPPLPWiyjAP7KwWRJMakBTWFTcAXwefUBCqRXw9tyaENYxiPTmiQDtTVrFap9Lz4', // RLP servisine giderken
      userData: [],
    }
  },
  methods: {
    goRLPService() {
      if (this.username && this.password) {
        axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check', {user: this.username})
            .then(response => {
              if (response.data.success) {
                console.log('response user', response.data.user)
                this.userData = response.data.user
                this.userData.role_type = 'student'
                let sinif = this.userData.class_name.split('-');
                this.userData.class = [
                  {
                    grade: sinif[0],
                    branch: sinif[1]
                  }
                ]
                this.userData.username = this.username
                console.log('userData - login', this.userData)
                var textJson = JSON.stringify(this.userData);
                var uriText = decodeURIComponent(textJson)
                var ciphertext = CryptoJS.AES.encrypt(uriText, this.secretKey).toString();
                // window.location.href='https://localhost:8080/#/rlp/?sso=' + btoa(ciphertext);
                window.location.href='https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext);
              } else {
                alert('kullanıcı bulunamadı')
              }
            })
            .catch(response => {
              console.log('ERR', response)
            })
      } else {
        alert('Formda eksik var.')
      }
    }
  }
}
</script>
