<script>
import Loading from "@/components/Loading.vue";
/*
kidzwonder
hexagonia
uesportals
highlights-library
myeduclass
myeduquiz
lgsxxlarge
myeduassessment
myeduexamns
raz-plus
sensations-english
english-play-box
ell-efs+
ell-english-academe-jr
richmondlp
maneno
news-o-matic

https://localhost:8080/#/k12net-redirect/?platform=kidzwonder

https://service.ueslms.com/#/k12net-redirect/?platform=kidzwonder
https://service.ueslms.com/#/k12net-redirect/?platform=uesportals
https://service.ueslms.com/#/k12net-redirect/?platform=myeduclass
https://service.ueslms.com/#/k12net-redirect/?platform=raz-plus
https://service.ueslms.com/#/k12net-redirect/?platform=ell
https://service.ueslms.com/#/k12net-redirect/?platform=sensations-english
https://service.ueslms.com/#/k12net-redirect/?platform=highlights-library
https://service.ueslms.com/#/k12net-redirect/?platform=news-o-matic
https://service.ueslms.com/#/k12net-redirect/?platform=efs-plus
https://service.ueslms.com/#/k12net-redirect/?platform=english-academe-jr
https://service.ueslms.com/#/k12net-redirect/?platform=richmondlp
 */

export default {
  name: "K12NetRedirect",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      // k12net_redirect_uri: 'https://localhost:8080/#/k12net',
      // k12net_url: 'https://api-test.k12net.com',
      // k12net_client_id: 'b0324b63-33ac-42ae-bbfd-129c18008764',
      k12net_url: 'https://api.k12net.com',
      k12net_client_id: '09150701-5163-4a58-b2a5-b859c20ed848',
      k12net_redirect_uri: 'https://service.ueslms.com/#/k12net',
      k12net_state: null,
    }
  },
  beforeMount() {
    this.loading = true
    const query = this.$route.query;
    if (query.platform) {
      this.k12net_state = query.platform;
      window.location = `${this.k12net_url}/GWCore.Web/connect/authorize?response_type=code&client_id=${this.k12net_client_id}&redirect_uri=${encodeURIComponent(this.k12net_redirect_uri)}&scope=openid profile&state=${this.k12net_state}`;
      // this.$router.push('/about');
      // console.log(redirect_url)
    } else {
      console.log('HATA: platform parametresi bulunamadi.')
    }
  },
}
</script>

<template>
  <div class="flex-center position-ref">
    <Loading :loading="loading"></Loading>
    <div class="content">
      <img src="/assets/logos/k12net.png">
      <br /><br /><br />
      <h3>Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
    </div>
  </div>
</template>
